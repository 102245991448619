<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('payment_plans')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('payment_plans')"
				              :isFilter="false"/>
			</template>
			<div class="row mb-5 align-items-center">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
					<ValidationObserver ref="createForm">
                        <div class="row">
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="periods" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('periods')">
                                                <payment-periods-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.periods">
                                                </payment-periods-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="products" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('products')">
                                                <payment-products-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.products">
                                                </payment-products-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="paymentPlanTypes" vid="paymentPlanTypes" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('payment_plan_types')">
                                                <payment-plan-types-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.types">
                                                </payment-plan-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="academicYear" rules="required_if:paymentPlanTypes,registration" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('academic_year')">
                                                <semesters-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.semester">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="registrationType" rules="required_if:paymentPlanTypes,registration" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('registration_type')">
                                                <parameter-selectbox
                                                    code="registration_types"
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.registrationType">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="faculty" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.faculty">
                                                </faculty-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="programLevel" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program_level')">
                                                <payment-program-level-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.programLevel">
                                                </payment-program-level-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                                        <ValidationProvider name="program" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="createForm.program"
                                                    :faculty_code="createForm.faculty">
                                                </program-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12">
                                        <b-button @click="createPaymentPlan"
                                                  type="button"
                                                  variant="primary"
                                                  class="btn-lg">
                                            {{ $t('payment_plan_create_btn').toUpper() }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <b-row>
                                    <b-col cols="12">
                                        <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_numbers')">
                                                <lined-textarea v-model="createForm.number"
                                                                :nowrap="false"
                                                                :disabled="false"
                                                                :styles="{ height: '300px'}"
                                                                :validateError="errors[0]">
                                                </lined-textarea>
                                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>

					</ValidationObserver>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
	import {ValidationObserver, ValidationProvider} from "vee-validate";
	import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
	import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";
	import PaymentPlanTypesSelectbox from "@/components/interactive-fields/PaymentPlanTypesSelectbox";
	import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
	import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
	import PaymentProgramLevelSelectbox from "@/components/interactive-fields/PaymentProgramLevelSelectbox";
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
	import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
	import PaymentService from "@/services/PaymentService";
    import LinedTextarea from "@/components/elements/LinedTextarea";
    import PaymentPlanService from "@/services/PaymentPlanService";

	export default {
		components: {
            LinedTextarea,
			AppLayout,
			Header,
			HeaderMobile,
			ValidationProvider,
			ValidationObserver,
			PaymentPeriodsSelectbox,
			PaymentProductsSelectbox,
			PaymentPlanTypesSelectbox,
			FacultySelectbox,
			ProgramSelectbox,
			PaymentProgramLevelSelectbox,
			SemestersSelectbox,
			ParameterSelectbox
		},
		data() {
			return {
				createForm: {
					periods: null,
					products: null,
					types: null,
					faculty: null,
					program: null,
					programLevel: null,
					semester: null,
					registrationType: null
				}
			}
		},
		metaInfo() {
			return {
				title: this.$t("payment_plan_title")
			}
		},
		methods: {
			async createPaymentPlan() {
                if(this.checkPermission('paymentplan_store')){
                    const isValid = await this.$refs.createForm.validate();
                    if (isValid) {

                        let oldValue=this.createForm.number;
                        if(this.createForm.number){
                            this.createForm.number=this.createForm.number.split(/\n/);
                        }

                        let formData = {
                            period_id: this.createForm.periods,
                            product_id: this.createForm.products,
                            type: this.createForm.types,
                            student_number: this.createForm.number
                        }

                        this.createForm.faculty != null ? formData.faculty_code = this.createForm.faculty : false;
                        this.createForm.program != null ? formData.program_code = this.createForm.program : false;
                        this.createForm.programLevel != null ? formData.program_level = this.createForm.programLevel : false;
                        if (this.createForm.types == 'registration') {
                            formData.registration_type = this.createForm.registrationType
                            formData.semester_id = this.createForm.semester
                        }
                        else {
                            this.createForm.registrationType != null ? formData.registration_type = this.createForm.registrationType : false;
                            this.createForm.semester != null ? formData.semester_id = this.createForm.semester : false;
                        }

                        PaymentService.createPlan(formData)
                                      .then(response => {
                                          this.$toast.success(this.$t('api.' + response.data.message));
                                          this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                                      })
                                      .catch(e => {
                                          if (e.status == '404' || e.status == '406') {
                                              if (e.data.message) {
                                                  this.$refs.createForm.errors.periods.push(this.$t('api.' + e.data.message));
                                              }
                                          }
                                          else {
                                              if (e.data.errors.period_id) {
                                                  this.$refs.createForm.errors.periods.push(e.data.errors.period_id[0]);
                                              }
                                              if (e.data.errors.product_id) {
                                                  this.$refs.createForm.errors.products.push(e.data.errors.product_id[0]);
                                              }
                                              if (e.data.errors.type) {
                                                  this.$refs.createForm.errors.paymentPlanTypes.push(e.data.errors.type[0]);
                                              }
                                              if (e.data.errors.faculty_code) {
                                                  this.$refs.createForm.errors.faculty.push(e.data.errors.faculty_code[0]);
                                              }
                                              if (e.data.errors.program_code) {
                                                  this.$refs.createForm.errors.program.push(e.data.errors.program_code[0]);
                                              }
                                              if (e.data.errors.program_level) {
                                                  this.$refs.createForm.errors.programLevel.push(e.data.errors.program_level[0]);
                                              }
                                              if (e.data.errors.registration_type) {
                                                  this.$refs.createForm.errors.registrationType.push(e.data.errors.registration_type[0]);
                                              }
                                              if (e.data.errors.semester_id) {
                                                  this.$refs.createForm.errors.semester.push(e.data.errors.semester_id[0]);
                                              }
                                          }
                                      }).finally(()=>{
                            this.createForm.number = oldValue;
                        })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
			}
		}
	}
</script>
